import { IMAGE_URL } from "../constants/constants";

export const getImageUrl = (path: string) => {
    if (!path) return '';
    
    // Check if the path is already a full URL
    if (path.startsWith('http://') || path.startsWith('https://')) {
      return path;
    }
    
    // Otherwise, combine with base URL
    return `${IMAGE_URL}${path}`;
  }; 