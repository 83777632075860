import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../config/api";
import { BASE_API } from "../../../constants/apiUrls";
import { toast } from "../../../lib/toast";
import {
  ErrorMessage,
  GetCommonData,
  Params,
  PostRequest,
} from "../../../types";
import { LeaveData, LeaveDataParams } from "../../../types/leave/leave";

// get all
export const getAllLeaveDataAsync = createAsyncThunk<
  GetCommonData<LeaveData>,
  Params<LeaveDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getAllLeaveData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getAllLeaveLogData, {
      params,
    });

    return {
      data: data.data?.data,
      length: data.data?.totalCount,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Leave data fail");
    return rejectWithValue(error);
  }
});

// // single
// export const singleLeaveDataAsync = createAsyncThunk<
//   LeaveData,
//   Params<GetSingleDataParams>
// >("admin/singleLeaveData", async ({ params }, { rejectWithValue }) => {
//   try {
//     const data = await AXIOS.get(`${BASE_API.getLeaveLogData}/${params?.id}`);

//     return data.data?.product as LeaveData;
//   } catch (err) {
//     const errors = err as AxiosError;
//     const error = errors.response?.data as ErrorMessage;
//     toast("error", error?.message || "Get leave fail");
//     return rejectWithValue(error);
//   }
// });

// update Leave Status
export const updateLeaveStatusAsync = createAsyncThunk<
  null,
  PostRequest<LeaveData, any>
>(
  "np-geo/updateLeaveStatus",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.leaveManagementUpdate}/${id}`, data);

      reRender(true);
      toast("success", "Status update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Status update fail");
      return rejectWithValue(error);
    }
  }
);
