import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../config/api";
import { BASE_API } from "../../../constants/apiUrls";
import { toast } from "../../../lib/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types";
import {
  GetLocationData,
  GetLocationDataParams,
} from "../../../types/location/location";
import { createOfficeLocation } from "../../../types/office/office";

// create
export const createLocationAsync = createAsyncThunk<
  null,
  PostRequest<createOfficeLocation, CommonOthers>
>(
  "admin/createLocation",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createLocation, data);

      reRender(true);
      toast("success", "Location create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Location create fail");
      return rejectWithValue(error);
    }
  }
);

// create
export const createBulkLocationAsync = createAsyncThunk<
  null,
  PostRequest<any, CommonOthers>
>(
  "admin/createLocation",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    const { userId } = data;

    try {
      const promises = userId?.userIdList?.map(async (id: string) => {
        const newData = {
          ...data,
          userId: id,
        };
        await AXIOS.post(BASE_API.createLocation, newData);
      });

      await Promise.all(promises);

      reRender(true);
      toast("success", "All locations set successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Location set fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllLocationDataAsync = createAsyncThunk<
  GetCommonData<GetLocationData>,
  Params<GetLocationDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getAllLocationData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.locationList, {
      params,
    });

    return {
      data: data.data?.data,
      length: data.data?.totalCount,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get location fail");
    return rejectWithValue(error);
  }
});

// update Leave Status
export const updateLocationAsync = createAsyncThunk<
  null,
  PostRequest<createOfficeLocation, any>
>(
  "np-geo/updateLeaveStatus",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.createLocation}/${id}`, data);

      reRender(true);
      toast("success", "Update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Update fail");
      return rejectWithValue(error);
    }
  }
);


export const removeOfficeLocationAsync = createAsyncThunk<
  null,
  { userId: string; officeId: string; reRender?: () => void }
>("admin/removeOfficeLocation", async ({ userId, officeId, reRender }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.removeOffice}/${userId}/${officeId}`);
    reRender?.();
    toast("success", "Office removed successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Remove office failed");
    return rejectWithValue(error);
  }
});


export const exportEmployeeAsync = createAsyncThunk<
  Blob,
  Params<GetSingleDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/exportEmployee", async ({ params }, { rejectWithValue }) => {
  try {
    const response = await AXIOS.get(BASE_API.exportEmployee, {
      params,
      responseType: 'blob'
    });

    return response.data;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Export Employee failed");
    return rejectWithValue(error);
  }
});