import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../config/api";
import { BASE_API } from "../../../constants/apiUrls";
import { toast } from "../../../lib/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types";
import {
  CreateMessageData,
  GetMessageData,
  GetMessageDataParams,
  GetMessageTypeData,
  GetMessageTypeDataParams,
} from "../../../types/message/message";

// create
export const createMessageCreationAsync = createAsyncThunk<
  null,
  PostRequest<CreateMessageData, CommonOthers>
>(
  "admin/createMessageCreation",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createMessage, data);

      reRender(true);
      toast("success", "Message create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Message create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllMessageAsync = createAsyncThunk<
  GetCommonData<GetMessageData>,
  Params<GetMessageDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getAllMessage", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.messageList}`, {
      params,
    });

    return {
      data: data.data?.data,
      length: data.data?.totalCount,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get message fail");
    return rejectWithValue(error);
  }
});

// update
export const updateMessageAsync = createAsyncThunk<
  null,
  PostRequest<GetMessageData, GetSingleDataParams>
>(
  "admin/updateMessage",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.updateMessage}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Message update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Message update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteDivisionAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-location/deleteDivision", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.deleteMessage}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Message delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Message delete fail");
    return rejectWithValue(error);
  }
});

// get all message type
export const getAllMessageTypeAsync = createAsyncThunk<
  GetCommonData<GetMessageTypeData>,
  Params<GetMessageTypeDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getAllMessageType", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.allMessageType}`, {
      params,
    });

    return {
      data: data.data?.data,
      length: data.data?.totalCount,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get message type fail");
    return rejectWithValue(error);
  }
});
