import { createSlice } from "@reduxjs/toolkit";
import {
  GetLocationData,
  LocationInitialStatus,
} from "../../../types/location/location";
import {
  createLocationAsync,
  getAllLocationDataAsync,
  updateLocationAsync,
} from "./LocationApi";

const initialState: LocationInitialStatus<GetLocationData> = {
  loading: false,
  manageLoading: false,
  locationData: [],
  countLocation: 0,
};

const locationSlice = createSlice({
  name: "admin-location",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create location
    builder
      .addCase(createLocationAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createLocationAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createLocationAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all location data
    builder
      .addCase(getAllLocationDataAsync.pending, (state) => {
        state.loading = true;
        state.locationData = [];
      })
      .addCase(getAllLocationDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.locationData = action.payload.data;
        state.countLocation = action.payload.length;
      })
      .addCase(getAllLocationDataAsync.rejected, (state) => {
        state.loading = false;
        state.locationData = [];
        state.countLocation = 0;
      });

    //update status
    builder
      .addCase(updateLocationAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateLocationAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateLocationAsync.rejected, (state) => {
        state.manageLoading = false;
      });
  },
});

export default locationSlice;
