import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../config/api";
import { BASE_API } from "../../../constants/apiUrls";
import { toast } from "../../../lib/toast";
import {
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
} from "../../../types";
import {
  AttendanceData,
  AttendanceDataParams,
} from "../../../types/attendance/attendance";

// get all
export const getAllAttendanceAsync = createAsyncThunk<
  GetCommonData<AttendanceData>,
  Params<AttendanceDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getAllAttendance", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.attendance, {
      params,
    });

    return {
      data: data.data?.getData,
      length: data.data?.totalCount,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get attendance fail");
    return rejectWithValue(error);
  }
});

// single
export const singleAttendanceAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin/singleAttendance", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.attendance}/${params?.id}`);

    return data.data?.areas as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Attendance data fail");
    return rejectWithValue(error);
  }
});


export const exportAttendanceAsync = createAsyncThunk<
  Blob,
  Params<AttendanceDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/exportAttendance", async ({ params }, { rejectWithValue }) => {
  try {
    const response = await AXIOS.get(BASE_API.exportAttendance, {
      params,
      responseType: 'blob'
    });

    return response.data;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Export attendance failed");
    return rejectWithValue(error);
  }
});