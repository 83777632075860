import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import appSlice from "./features/appSlice";
import attendanceSlice from "./features/attendance/AttendanceSlice";
import loginSlice from "./features/auth/loginSlice";
import employeeSlice from "./features/employee/EmployeeSlice";
import geoSlice from "./features/Geo/Area/AreaSlice";
import leaveSlice from "./features/leave/LeaveSlice";
import locationSlice from "./features/location/LocationSlice";
import messageCreationSlice from "./features/message/AttendanceSlice";
import officeSlice from "./features/office/OfficeSlice";
import overviewSlice from "./features/overView/OverviewSlice";
import roleSlice from "./features/role/RoleSlice";

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    login: loginSlice.reducer,
    geo: geoSlice.reducer,
    overview: overviewSlice.reducer,
    employeeList: employeeSlice.reducer,
    attendance: attendanceSlice.reducer,
    office: officeSlice.reducer,
    leave: leaveSlice.reducer,
    location: locationSlice.reducer,
    role: roleSlice.reducer,
    message: messageCreationSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
