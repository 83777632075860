import { createSlice } from "@reduxjs/toolkit";
import {
  AreaData,
  GeoInitialStatus,
  RegionData,
  TeritoryData,
} from "../../../../types/geo";
import {
  getAllAreaAsync,
  getAllRegionAsync,
  getAllTeritoryAsync,
} from "./AreaApi";

const initialState: GeoInitialStatus<AreaData, RegionData, TeritoryData> = {
  loading: false,
  manageLoading: false,
  ariaData: [],
  regionData: [],
  teritoryData: [],
  countArea: 0,
  countRegion: 0,
  countTeritory: 0,
};

const geoSlice = createSlice({
  name: "admin-geo-area",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all
    builder
      .addCase(getAllAreaAsync.pending, (state) => {
        state.loading = true;
        state.ariaData = [];
      })
      .addCase(getAllAreaAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.ariaData = action.payload.data;
        state.countArea = action.payload.length;
      })
      .addCase(getAllAreaAsync.rejected, (state) => {
        state.loading = false;
        state.ariaData = [];
        state.countArea = 0;
      });

    // get region
    builder
      .addCase(getAllRegionAsync.pending, (state) => {
        state.loading = true;
        state.regionData = [];
      })
      .addCase(getAllRegionAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.regionData = action.payload.data;
        state.countRegion = action.payload.length;
      })
      .addCase(getAllRegionAsync.rejected, (state) => {
        state.loading = false;
        state.regionData = [];
        state.countRegion = 0;
      });

    // get teritory
    builder
      .addCase(getAllTeritoryAsync.pending, (state) => {
        state.loading = true;
        state.teritoryData = [];
      })
      .addCase(getAllTeritoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.teritoryData = action.payload.data;
        state.countTeritory = action.payload.length;
      })
      .addCase(getAllTeritoryAsync.rejected, (state) => {
        state.loading = false;
        state.teritoryData = [];
        state.countTeritory = 0;
      });
  },
});

export default geoSlice;
