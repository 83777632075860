import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../config/api";
import { BASE_API } from "../../../../constants/apiUrls";
import { toast } from "../../../../lib/toast";
import {
  CommonParams,
  ErrorMessage,
  GetCommonData,
  Params,
} from "../../../../types";
import { AreaData, RegionData, TeritoryData } from "../../../../types/geo";

// get all area
export const getAllAreaAsync = createAsyncThunk<
  GetCommonData<AreaData>,
  Params<CommonParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getAllArea", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.area, {
      params,
    });

    return {
      data: data.data?.getData,
      length: data.data?.totalCount,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get area fail");
    return rejectWithValue(error);
  }
});

// get all region
export const getAllRegionAsync = createAsyncThunk<
  GetCommonData<RegionData>,
  Params<CommonParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getAllRegion", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.region, {
      params,
    });

    return {
      data: data.data?.getData,
      length: data.data?.totalCount,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get region fail");
    return rejectWithValue(error);
  }
});

// get all area
export const getAllTeritoryAsync = createAsyncThunk<
  GetCommonData<TeritoryData>,
  Params<CommonParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getAllTeritory", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.teritory, {
      params,
    });

    return {
      data: data.data?.getData,
      length: data.data?.totalCount,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get teritory fail");
    return rejectWithValue(error);
  }
});
