import axios from "axios";
import { API_URL, AUTH_SAVE } from "../constants/constants";

const AXIOS = axios.create({
  baseURL: API_URL,
});

AXIOS.interceptors.response.use(
  (response) => response,
  (error) => {
    if (process.env.NODE_ENV !== "production") {
      console.log("GLOBAL_ERROR", error.response);
    }

    if (error.response?.status === 401 && !error.config.url?.includes('/login')) {
      localStorage.removeItem(`${AUTH_SAVE}`);
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default AXIOS;
