import { createSlice } from "@reduxjs/toolkit";
import {
  GetMessageData,
  GetMessageTypeData,
  MessageInitialStatus,
} from "../../../types/message/message";
import {
  createMessageCreationAsync,
  deleteDivisionAsync,
  getAllMessageAsync,
  getAllMessageTypeAsync,
  updateMessageAsync,
} from "./AttendanceApi";

const initialState: MessageInitialStatus<GetMessageData, GetMessageTypeData> = {
  loading: false,
  manageLoading: false,
  messageData: [],
  messageType: [],
  singleMessageData: null,
  countMessage: 0,
  countMessageType: 0,
};

const messageCreationSlice = createSlice({
  name: "admin-message",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create division
    builder
      .addCase(createMessageCreationAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createMessageCreationAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createMessageCreationAsync.rejected, (state) => {
        state.manageLoading = false;
      });
    // get all
    builder
      .addCase(getAllMessageAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMessageAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.messageData = action.payload.data;
        state.countMessage = action.payload.length;
      })
      .addCase(getAllMessageAsync.rejected, (state) => {
        state.loading = false;
        state.messageData = [];
        state.countMessage = 0;
      });
    // update division
    builder
      .addCase(updateMessageAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateMessageAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateMessageAsync.rejected, (state) => {
        state.manageLoading = false;
      });
    // delete division
    builder
      .addCase(deleteDivisionAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteDivisionAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteDivisionAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all division
    builder
      .addCase(getAllMessageTypeAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMessageTypeAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.messageType = action.payload.data;
        state.countMessageType = action.payload.length;
      })
      .addCase(getAllMessageTypeAsync.rejected, (state) => {
        state.loading = false;
        state.messageType = [];
        state.countMessageType = 0;
      });
  },
});

export default messageCreationSlice;
