export const BASE_API = {
  // Auth
  login: "/user/login",

  // geo
  region: "/region/get-regions",
  area: "/area/get-areas",
  teritory: "/teritory/get-teritories",

  // Overview
  overviewCount: "user/admin/get-all-counts",
  overviewChart: "/attendance/chart-data",

  //employee
  // employeeList: "/user/admin/get-users",
  getUsers: "/user/admin/get-user-by", // upper part
  getAttendanceSummery: "/attendance/get-stats-by", // middle part
  getAttendanceLogData: "/attendance/get-data", // lower part-1
  getAllLeaveLogData: "/leave-management/get-all", // lower part-2

  //Attendance
  attendance: "/attendance/get-data",
  attendanceLog: "/attendance/export-attendance",

  //Leave management
  leaveManagementUpdate: "/leave-management/update-status-by",

  // office data
  officeList: "/office-location/get-offices",
  createOffice: "/office-location/create-office",
  deleteOffice: "/office-location/delete-office-by",
  editOffice: "/office-location/update-office-by",

  //location management
  createLocation: "/location-management/assign-office",
  locationList: "/user/admin/get-users",

  // message creation
  createMessage: "/message/v1/create",
  messageList: "/message/v1/get-all",
  deleteMessage: "/message/delete",
  updateMessage: "/message/update",
  allMessageType: "/message/message-types",

  //role
  roleManage: "/user/admin/update-user-by",

  // (File Upload)
  fileUpload: `/user/v1/file-or-image-upload`,

  resetPassword: `/user/admin/update-userpass-by`,
  exportAttendance: `/attendance/export-attendance`,
  exportOffice: `/office-location/export-offices`,
  exportEmployee: `/user/admin/export-employees`,
  removeOffice: `/location-management/remove-office`,
};
