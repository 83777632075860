import AttendanceIcon from "../assets/icon/sidebarIcon/AttendanceIcon";
import EmployeeIcon from "../assets/icon/sidebarIcon/EmployeeIcon";
import LocationIcon from "../assets/icon/sidebarIcon/LocationIcon";
import MessageIcon from "../assets/icon/sidebarIcon/MessageIcon";
import OfficeManageIcon from "../assets/icon/sidebarIcon/OfficeManageIcon";
import OverviewIcon from "../assets/icon/sidebarIcon/OverviewIcon";
import RoleManageIcon from "../assets/icon/sidebarIcon/RoleManageIcon";

const AllSidebarMenu = [
  {
    name: "Overview",
    path: "/admin/dashboard",
    icon: OverviewIcon,
    role: ["ADMIN"],
  },

  {
    name: "Employee",
    path: "/admin/employee",
    icon: EmployeeIcon,
    role: ["ADMIN"],
  },

  {
    name: "Attendance",
    path: "/admin/attendance",
    icon: AttendanceIcon,
    role: ["ADMIN"],
  },

  {
    name: "Office Management",
    path: "/admin/office-management",
    icon: OfficeManageIcon,
    role: ["ADMIN"],
  },

  {
    name: "Location Management",
    path: "/admin/location-management",
    icon: LocationIcon,
    role: ["ADMIN"],
  },

  {
    name: "Role Management",
    path: "/admin/role-management",
    icon: RoleManageIcon,
    role: ["ADMIN"],
  },

  {
    name: "Message Creation",
    path: "/admin/message-creation",
    icon: MessageIcon,
    role: ["ADMIN"],
  },
];

export default AllSidebarMenu;
