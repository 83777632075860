import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../config/api";
import { BASE_API } from "../../../constants/apiUrls";
import { toast } from "../../../lib/toast";
import { ErrorMessage, GetCommonData, Params } from "../../../types";
import {
  OverviewChartData,
  OverviewChartDataParams,
  OverviewCountData,
} from "../../../types/overview/overview";

// get count
export const getAllCountAsync = createAsyncThunk<
  GetCommonData<OverviewCountData>,
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getAllCount", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.overviewCount, {
      params,
    });

    return {
      data: data?.data?.data,
      length: data.data?.totalCount,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get attendance fail");
    return rejectWithValue(error);
  }
});

// get overview chart data
export const getOverviewChartAsync = createAsyncThunk<
  GetCommonData<OverviewChartData>,
  Params<OverviewChartDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getOverviewChart", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.overviewChart, {
      params,
    });
    return {
      data: data?.data?.data,
      length: data.data?.totalCount,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get attendance fail");
    return rejectWithValue(error);
  }
});
