import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../config/api";
import { BASE_API } from "../../../constants/apiUrls";
import { toast } from "../../../lib/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types";
import {
  CreateOffice,
  OfficeListData,
  OfficeListDataParams,
} from "../../../types/office/office";

// create
export const createOfficeAsync = createAsyncThunk<
  null,
  PostRequest<CreateOffice, CommonOthers>
>(
  "admin/createOffice",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createOffice, data);

      reRender(true);
      toast("success", "Office create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Office create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllOfficeListAsync = createAsyncThunk<
  GetCommonData<OfficeListData>,
  Params<OfficeListDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getAllOfficeList", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.officeList, {
      params,
    });

    return {
      data: data.data?.data,
      length: data.data?.totalCount,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get office fail");
    return rejectWithValue(error);
  }
});

// update
export const updateOfficeAsync = createAsyncThunk<
  null,
  PostRequest<CreateOffice, GetSingleDataParams>
>(
  "admin/updateOffice",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.editOffice}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Office update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Office update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteOfficeAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin/deleteOffice", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.deleteOffice}/${params?.id}`);

    params?.reRender && params?.reRender(true);
    toast("success", "Office delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Office delete fail");
    return rejectWithValue(error);
  }
});


export const exportOfficeAsync = createAsyncThunk<
  Blob,
  Params<GetSingleDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/exportOffice", async ({ params }, { rejectWithValue }) => {
  try {
    const response = await AXIOS.get(BASE_API.exportOffice, {
      params,
      responseType: 'blob'
    });

    return response.data;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Export office failed");
    return rejectWithValue(error);
  }
});